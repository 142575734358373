export const beautifyICO = (dateString: string) => {
  const dateObject = new Date(dateString);

  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();

  const formattedDate = `${+day < 9 ? `0${day}` : day}-${
    +month < 9 ? `0${month}` : month
  }-${year}`;
  return formattedDate;
};
