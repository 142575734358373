import { AutoComplete, Input, SelectProps } from "antd";
import { API } from "../../app/services/ApiService";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { updateSearchQuery } from "../../app/commonSlice";

const SearchInput = () => {
  const { Search } = Input;
  const navigate = useNavigate();

  const [search, { data }] = API.useLazySearchQuery();

  const dispatch = useAppDispatch();

  const searchResult = (query: string) =>
    data?.map((item, idx) => {
      return {
        value: item.name,
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>{item.name}</span>
          </div>
        ),
      };
    });

  const [options, setOptions] = useState<SelectProps<object>["options"]>([]);

  const handleSearch = (value: string) => {
    setOptions(value ? searchResult(value) : []);
    dispatch(updateSearchQuery(value));
    search({ fileName: value, orderDate: false, orderName: false });
  };

  const onSelect = (value: string) => {
    dispatch(updateSearchQuery(value));
    navigate(`/search`);
  };
  return (
    <>
      <AutoComplete
        dropdownMatchSelectWidth={252}
        style={{ width: 300 }}
        options={options}
        onSelect={onSelect}
        onSearch={handleSearch}
        notFoundContent={"Результатов не найдено"}
      >
        <Search allowClear placeholder="Поиск" enterButton />
      </AutoComplete>
    </>
  );
};

export default SearchInput;
