import { FC, useEffect, useState } from "react";
import Table from "../Table/Table";
import { IFile } from "../../app/types";
import { FileOutlined, ReloadOutlined } from "@ant-design/icons";
import { beautifyICO } from "../../utils/timeFormatter";
import FileForm from "../FileForm/FileForm";
import { getFileExtension } from "../../utils/getFileExtension";
import { API } from "../../app/services/ApiService";
import Sort from "../Sort/Sort";
import CopyLink from "./CopyLink";
import { Button, Tooltip } from "antd";
import s from "./TableOfFiles.module.css";

interface Props {
  files: IFile[] | undefined;
  updateFileList: VoidFunction;
  showSort?: boolean;
  isLoading: boolean;
}

const TableOfFiles: FC<Props> = ({
  files,
  updateFileList,
  showSort = true,
  isLoading,
}) => {
  const [addToFavorite, { isLoading: isAddToFavoriteLoading }] =
    API.useAddToFavoriteMutation();
  const [deleteFromFavorite, { isLoading: isDeleteFromFavoriteLoading }] =
    API.useDeleteFromFavoriteMutation();
  const [
    updateFile,
    {
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      isLoading: isUpdateLoading,
    },
  ] = API.useUpdateFileMutation();
  const [deleteFile, { isLoading: isDeleteFileLoading }] =
    API.useDeleteFilesMutation();
  const [editingFile, setEditingFile] = useState<IFile>();
  const [isFileEditingModalOpen, setIsFileEditingModalOpen] = useState(false);

  useEffect(() => {
    updateFileList();
  }, []);

  useEffect(() => {
    if (isUpdateSuccess) {
      setIsFileEditingModalOpen(false);
    }
  }, [isUpdateSuccess]);

  const setFavorite = (id: string | number) => {
    files?.forEach((item) => {
      if (item.id == id && !item.favorite) {
        addToFavorite({ id: id });
      } else if (item.id == id && item.favorite) {
        deleteFromFavorite({ id: id });
      }
    });
    updateFileList();
  };

  const deleteItems = (payload: Array<string | number>) => {
    deleteFile({ ids: payload });
    setTimeout(() => {
      updateFileList();
    }, 1000);
  };

  const downloadFile = async ({
    file,
    showFile,
  }: {
    file: IFile;
    showFile?: boolean;
  }) => {
    const downloadUrl = `${process.env.REACT_APP_API_URL}download?file_id=${file.id}`;

    try {
      const response = await fetch(downloadUrl, {
        credentials: "include",
      });
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      if (!showFile) link.download = file.name;

      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <>
      <div style={{ display: "flex", gap: "20px" }}>
        {showSort && (
          <Sort
            style={{ textAlign: "left", lineHeight: "20px" }}
            updateFileList={updateFileList}
          />
        )}
        <Tooltip title="Обновить">
          <Button
            onClick={() => updateFileList()}
            shape="circle"
            icon={<ReloadOutlined />}
          />
        </Tooltip>
      </div>

      <Table
        tableHeadList={{
          title: "Название",
          size: "Размер",
          lastModified: "Дата изменения",
          link: "",
        }}
        tableData={files?.map((item, index) => ({
          initial_object: item,

          title: (
            <div
              onClick={() => downloadFile({ file: item, showFile: true })}
              className={s.tableTitle}
            >
              <FileOutlined style={{ marginRight: "5px" }} />
              {`${item.name}.${getFileExtension(item.pure_name)}`}
            </div>
          ),
          key: index,
          size: item.size,
          lastModified: beautifyICO(item.update_date),
          link: <CopyLink id={item.id} />,
        }))}
        setFavorite={setFavorite}
        deleteItems={deleteItems}
        downloadItem={(data) => downloadFile({ file: data as IFile })}
        setEditingFile={(data) => setEditingFile(data as IFile)}
        openEditModal={() => setIsFileEditingModalOpen(true)}
        isLoading={
          isDeleteFileLoading ||
          isUpdateLoading ||
          isDeleteFromFavoriteLoading ||
          isAddToFavoriteLoading ||
          isLoading
        }
      />

      <FileForm
        initialValues={editingFile}
        isModalOpen={isFileEditingModalOpen}
        setIsModalOpen={setIsFileEditingModalOpen}
        saveData={updateFile}
        isError={isUpdateError}
        isLoading={isUpdateLoading}
      />
    </>
  );
};

export default TableOfFiles;
