import { Result } from "antd";
import { Link } from "react-router-dom";

export default function ErrorPage() {
  return (
    <div id="error-page">
      <Result
        subTitle="Что-то пошло не так"
        extra={
          <Link to="/" className="link" type="primary">
            На главную
          </Link>
        }
      />
    </div>
  );
}
