import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import {
  ECategoriesKeys,
  ICommonInfo,
  IFile,
  IFileWithLinks,
  ISendingFilesValues,
} from "../types";

export const API = createApi({
  reducerPath: "API",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    prepareHeaders: (headers) => {
      return headers;
    },
    credentials: "include",
  }) as BaseQueryFn,
  endpoints: (build) => ({
    login: build.mutation<{ access_token: string }, FormData>({
      query: (data) => ({
        url: `/login`,
        method: "POST",
        body: data,
      }),
    }),
    logout: build.mutation<void, void>({
      query: () => ({
        url: `/logout`,
        method: "POST",
      }),
    }),
    getFiles: build.query<
      IFile[],
      {
        skip?: number;
        limit?: number;
        orderName?: boolean;
        orderDate?: boolean;
        category?: ECategoriesKeys;
      }
    >({
      query: ({ orderDate, orderName, limit, skip, category }) =>
        `/?skip=${skip || 0}&limit=${limit || 100}${
          category ? `&category=${category}` : ""
        }${orderName !== undefined ? `&order_name=${orderName}` : ""}${
          orderDate !== undefined ? `&order_date=${orderDate}` : ""
        }`,
    }),
    getFavoriteFiles: build.query<
      IFile[],
      {
        orderName?: boolean;
        orderDate?: boolean;
      }
    >({
      query: ({ orderDate, orderName }) =>
        `/favorite?order_name=${!!orderName}&order_date=${!!orderDate}`,
    }),
    search: build.query<
      IFile[],
      { fileName: string; orderName?: boolean; orderDate?: boolean }
    >({
      query: ({ fileName, orderDate, orderName }) =>
        `/search?file_name=${fileName}&order_name=${!!orderName}&order_date=${!!orderDate}`,
    }),

    uploadFile: build.mutation<void, ISendingFilesValues>({
      query: ({ category, file, name }) => ({
        url: `/upload?name=${name}&category=${category}`,
        method: "POST",
        body: file,
      }),
    }),
    updateFile: build.mutation<
      void,
      ISendingFilesValues & { id: string | number }
    >({
      query: ({ category, file, name, id }) => ({
        url: `/update?file_id=${id}&name=${name}&category=${category}`,
        method: "PUT",
        body: file,
      }),
    }),
    deleteFiles: build.mutation<void, { ids: Array<string | number> }>({
      query: (data) => ({
        url: `/delete`,
        method: "DELETE",
        body: data.ids,
      }),
    }),

    addToFavorite: build.mutation<void, { id: string | number }>({
      query: ({ id }) => ({
        url: `/favorite?file_id=${id}`,
        method: "POST",
      }),
    }),
    deleteFromFavorite: build.mutation<void, { id: string | number }>({
      query: ({ id }) => ({
        url: `/favorite?file_id=${id}`,
        method: "DELETE",
      }),
    }),
    shortLink: build.query<{ data: IFileWithLinks }, { id: string | number }>({
      query: ({ id }) => `/short_link?file_id=${id}`,
    }),
    commonInfo: build.query<ICommonInfo, void>({
      query: () => `/info`,
    }),
  }),
});
