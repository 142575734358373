import React, { FC, useState } from "react";
import {
  Button,
  Table as TableComponent,
  TablePaginationConfig,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
  CopyOutlined,
  EditOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { TableData, TableProps } from "./Table.props";
import FavoriteButton from "../FavoriteButton/FavoriteButton";
import LoadingCover from "../StatusComponents/LoadingCover";

const Table: FC<TableProps> = ({
  tableData,
  tableHeadList,
  setFavorite,
  deleteItems,
  downloadItem,
  setEditingFile,
  openEditModal,
  isLoading,
}) => {
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    total: tableData?.length,
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState<[] | TableData[]>([]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setPagination(pagination);
  };

  // favorite
  const rows = tableData?.map((item) => ({
    ...item,
    edit: (
      <>
        <Tooltip title="Редактировать">
          <Button
            onClick={() => {
              openEditModal();
              setEditingFile(item.initial_object);
            }}
            shape="circle"
            icon={<EditOutlined />}
          />
        </Tooltip>
      </>
    ),
    download: (
      <>
        <Tooltip title="Скачать">
          <Button
            onClick={() => {
              downloadItem(item.initial_object);
            }}
            shape="circle"
            icon={<DownloadOutlined />}
          />
        </Tooltip>
      </>
    ),
    ...(setFavorite && {
      favorite: (
        <FavoriteButton
          isFavorite={item.initial_object.favorite}
          setIsFavorite={() => {
            setFavorite(item.initial_object.id);
          }}
        />
      ),
    }),
  }));

  const columns = [];
  const editedTableHeadList = {
    favorite: "",
    ...tableHeadList,
    edit: "",
    download: "",
  };
  for (const [key, value] of Object.entries(editedTableHeadList)) {
    columns.push({
      title: value,
      dataIndex: key,
      key: key,
    });
  }

  const deleteItemsFn = () => {
    deleteItems(selectedRowKeys.map((item) => item.initial_object.id));
    setSelectedRowKeys([]);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
          gap: "20px",
        }}
      >
        {isLoading && <LoadingCover />}

        {selectedRowKeys.length > 0 && (
          <Button
            onClick={deleteItemsFn}
            danger
            icon={<DeleteOutlined />}
            size="middle"
          >
            Удалить
          </Button>
        )}
      </div>

      <TableComponent
        rowSelection={{
          selectedRowKeys: selectedRowKeys.map((item) => item.key),
          type: "checkbox",
          onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
            setSelectedRowKeys(selectedRows);
          },
        }}
        columns={columns}
        dataSource={rows}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </>
  );
};

export default Table;
