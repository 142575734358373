import { FC, ReactNode } from "react";
import s from "./Title.module.css";
import cn from "classnames";

interface Props {
  children: ReactNode;
  size?: "l" | "m" | "s" | "xs";
}

const Title: FC<Props> = ({ children, size = "m", ...props }) => {
  return (
    <h1
      className={cn(s.title, {
        [s.large]: size === "l",
        [s.medium]: size === "m",
        [s.small]: size === "s",
        [s.extraSmall]: size === "xs",
      })}
      {...props}
    >
      {children}
    </h1>
  );
};

export default Title;
