import { useParams } from "react-router-dom";
import Title from "../Title/Title";
import { API } from "../../app/services/ApiService";
import TableOfFiles from "../TableOfFiles/TableOfFiles";
import { useAppSelector } from "../../app/hooks";
import { getSortType } from "../../utils/getSortType";
import { ECategories, ECategoriesKeys } from "../../app/types";
import ErrorPage from "../StatusComponents/ErrorPage";

const ContentTypePage = () => {
  const sortType = useAppSelector((state) => state.commonSlice.sortType);

  const params = useParams();

  const [getFiles, { data: files, isFetching: filesIsLoading }] =
    API.useLazyGetFilesQuery();

  if (!params.type || !ECategories[params.type as ECategoriesKeys]) {
    return <ErrorPage />;
  }

  return (
    <div style={{ position: "relative" }} className="contentWrapper">
      <Title>{ECategories[params.type as ECategoriesKeys]}</Title>

      <TableOfFiles
        isLoading={filesIsLoading}
        files={files}
        updateFileList={() =>
          getFiles({
            ...getSortType(sortType),
            ...(params.type && { category: params.type as ECategoriesKeys }),
          })
        }
      />
    </div>
  );
};

export default ContentTypePage;
