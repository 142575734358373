export function setCookie(
  cookieName: string,
  cookieValue: string,
  hours?: number
) {
  const d = new Date();
  hours && d.setTime(d.getTime() + hours * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = cookieName + "=" + cookieValue + ";path=/";
}

export function getCookie(cookieName: string) {
  const name = cookieName + "=";

  const ca = document ? document.cookie.split(";") : [];

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return "";
}

export function checkCookie(cookieName: string) {
  const cookieExist = getCookie(cookieName);
  if (cookieExist !== "") {
    return cookieExist;
  }
  return false;
}

export function deleteCookie(cookieName: string) {
  setCookie(cookieName, "");
}
