import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

const BackButton = () => {
  const pathname = useLocation().pathname;

  const navigate = useNavigate();
  return (
    <Tooltip title="Назад">
      <Button
        style={{
          marginRight: "auto",
          width: "auto",
          display: pathname === "/" ? "none" : "block",
        }}
        shape="circle"
        icon={<ArrowLeftOutlined />}
        onClick={() => navigate(-1)}
      />
    </Tooltip>
  );
};

export default BackButton;
