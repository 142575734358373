import React, { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import s from './Spinner.module.css';
import cn from 'classnames';

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  size?: 'sm' | 'md' | 'lg';
}

const Spinner: FC<Props> = ({ size = 'lg', className, ...props }) => {
  return (
    <div {...props} className={cn(s.container, className)}>
      <div
        className={cn(s.spinner, {
          [s.spinnerLg]: size === 'lg',
          [s.spinnerMd]: size === 'md',
          [s.spinnerSm]: size === 'sm',
        })}
      ></div>
    </div>
  );
};

export default Spinner;
