import { ESortKeys } from "../app/types";

export const getSortType = (sortType: ESortKeys) => {
  //if orderDate==false - new first
  //if order_name == false - я - а
  switch (sortType) {
    case "newFirst":
      return {
        orderDate: false,
      };

    case "oldFirst":
      return {
        orderDate: true,
      };

    case "alphabetAsc":
      return {
        orderName: true,
      };
    case "alphabetDsc":
      return {
        orderName: false,
      };

    default:
      return {};
  }
};
