import React, { DetailedHTMLProps, FC, HTMLAttributes } from "react";
import Spinner from "./Spinner/Spinner";

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  spinnerSize?: "sm" | "md" | "lg";
}

const LoadingCover: FC<Props> = ({ spinnerSize = "md", ...props }) => {
  return (
    <div
      {...props}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        backgroundColor: "var(--white)",
        opacity: "0.7",
        zIndex: 99,
      }}
    >
      <Spinner
        color="primary"
        size={spinnerSize}
        style={{
          opacity: "1",
          zIndex: 99,
        }}
      />
    </div>
  );
};

export default LoadingCover;
