import { Select } from "antd";
import { SortAscendingOutlined } from "@ant-design/icons";
import { ESort, ESortKeys } from "../../app/types";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { chooseSortType } from "../../app/commonSlice";
import { DetailedHTMLProps, FC, HTMLAttributes } from "react";

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  updateFileList: VoidFunction;
}

const Sort: FC<Props> = ({ updateFileList, ...props }) => {
  const sortType = useAppSelector((state) => state.commonSlice.sortType);
  const dispatch = useAppDispatch();
  const handleChangeSelect = (value: ESortKeys) => {
    dispatch(chooseSortType(value));
    updateFileList();
  };

  return (
    <div {...props}>
      <Select
        suffixIcon={<SortAscendingOutlined />}
        value={sortType}
        onChange={handleChangeSelect}
        options={Object.entries(ESort).map((item) => ({
          value: item[0],
          label: item[1],
        }))}
      />
    </div>
  );
};

export default Sort;
