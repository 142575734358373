import { ReactNode } from "react";
export enum ECategories {
  image = "Изображения",
  other = "Другое",
  document = "Документы",
  video = "Видео",
}

export type ECategoriesValues = (typeof ECategories)[keyof typeof ECategories];
export type ECategoriesKeys = keyof typeof ECategories;

export enum ESort {
  newFirst = "От новых к старым",
  oldFirst = "От старых к новым",
  alphabetAsc = "По алфавиту (А → Я)",
  alphabetDsc = "По алфавиту (Я → А)",
}

export type ESortValues = (typeof ESort)[keyof typeof ESort];
export type ESortKeys = keyof typeof ESort;

export interface IFile {
  id: string | number;
  name: string;
  pure_name: string;
  size: string;
  favorite: boolean;
  category: ECategoriesKeys;
  upload_date: string;
  update_date: string;
}

export interface IFileWithLinks extends IFile {
  tiny_url: string;
  url: string;
}

export interface ISendingFilesValues {
  id: string | number;
  name: string;
  category: ECategoriesKeys;
  file: File;
}

export interface ILoginValues {
  username: string;
  password: string;
}

export interface IFullCategoriesData {
  category: ECategoriesValues;
  count: number;
  icon: ReactNode;
  iconColor: string;
  size: string;
  title: string;
  link: string;
}

export interface ICategoryInfo {
  category: ECategoriesKeys;
  count: number;
  size: string;
  size_kb: string;
}
export interface ICommonInfo {
  files: Array<ICategoryInfo>;
  total: {
    count: number;
    total_size: string;
    total_size_kb: string;
  };
}
