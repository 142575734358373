import { FC, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { getCookie } from "../utils/cookies";

const ProtectedRoute: FC = () => {
  const navigate = useNavigate();
  const tokenName =
    process.env.NODE_ENV === "development" ? "test_token" : "auth_token";
  const token = getCookie(tokenName);
  const [isAuth, setIsAuth] = useState(process.env.NODE_ENV === "development");

  useEffect(() => {
    setIsAuth(!!token);
  }, [token]);

  useEffect(() => {
    if (!isAuth) {
      navigate("/login");
    }
  }, [isAuth]);

  return <Outlet />;
};

export default ProtectedRoute;
