import React, { FC, useState } from "react";
import { Divider, Layout, Space } from "antd";
import Menu from "../Menu/Menu";
import HeaderComponent from "./../Header/Header";
import s from "./MainLayout.module.css";
import { Outlet } from "react-router-dom";
import cn from "classnames";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import BackButton from "../BackButton/BackButton";
const { Header, Sider, Content } = Layout;

const MainLayout: FC<{ logout: VoidFunction }> = ({ logout }) => {
  const [collapsed, setCollapsed] = useState(false);

  const headerStyle: React.CSSProperties = {
    textAlign: "center",
    color: "#fff",
    height: 64,
    paddingInline: 24,
    lineHeight: "64px",
    backgroundColor: "var(--white)",
  };

  const contentStyle: React.CSSProperties = {
    textAlign: "center",
    minHeight: "200px",
    lineHeight: "120px",
    color: "#fff",
    backgroundColor: "var(--white)",
    paddingLeft: collapsed ? "20px" : "50px",
    overflow: "auto",
  };

  const siderStyle: React.CSSProperties = {
    backgroundColor: "var(--white)",
    zIndex: 99,
  };

  const customTrigger = (
    <div
      style={{
        backgroundColor: "#fafafa",
        color: "var(--coldGrey)",
        borderTop: "1px solid #f0f0f0",
      }}
    >
      {collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
    </div>
  );

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={[0, 48]}>
      <Layout>
        <Header style={headerStyle}>
          <HeaderComponent logout={logout} />
        </Header>
        <Divider style={{ margin: 0 }} />
        <Layout>
          <Sider
            style={siderStyle}
            width={256}
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            trigger={customTrigger}
          >
            <Menu />
          </Sider>
          <Content style={contentStyle}>
            <div className={cn(s.backButtonWrapper)}>
              <BackButton />
            </div>
            <div className={cn(s.contentWrapper)}>
              <Outlet />
            </div>
          </Content>
        </Layout>
      </Layout>
    </Space>
  );
};

export default MainLayout;
