import { FC, useEffect, useState } from "react";
import { Alert, Button, Form, Input, Modal, Select, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import s from "./FileForm.module.css";
import { ECategories, IFile, ISendingFilesValues } from "../../app/types";
import LoadingCover from "../StatusComponents/LoadingCover";
import { API } from "../../app/services/ApiService";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (payload: boolean) => void;
  initialValues?: IFile;
  saveData: (payload: ISendingFilesValues) => void;
  isError: boolean;
  isLoading: boolean;
}

const FileForm: FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  initialValues,
  saveData,
  isError,
  isLoading,
}) => {
  const [chooseNewFile, setChooseNewFile] = useState(false);

  const [getFiles] = API.useLazyGetFilesQuery();

  const onFinish = (values: any) => {
    const formData = new FormData();
    values.file && formData.append("file", values.file[0].originFileObj);

    saveData({
      ...values,
      ...(!!values.file && { file: formData }),
      ...(initialValues && { id: initialValues.id }),
    });

    setTimeout(() => {
      getFiles({ orderDate: false });
    }, 1000);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  const beforeUpload = () => {
    return false;
  };

  const [form] = Form.useForm();

  useEffect(() => {
    if (!isModalOpen) {
      form.resetFields();
      setChooseNewFile(false);
    }
  }, [isModalOpen]);

  const editedInitialValues = [];
  const editedTableHeadList = { ...initialValues };
  for (const [key, value] of Object.entries(editedTableHeadList)) {
    editedInitialValues.push({
      name: value,
      dataIndex: key,
      key: key,
    });
  }

  function convertObjectToArray(obj: Record<string, any>) {
    return Object.entries(obj).map(([name, value]) => ({ name, value }));
  }

  return (
    <>
      <Modal
        title={
          <div className={s.title}>
            {initialValues ? "Редактировать файл" : "Добавить файл"}
          </div>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div className={s.formWrapper}>
          {isLoading && <LoadingCover />}

          <Form
            form={form}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 25 }}
            style={{ maxWidth: 600, minWidth: 400 }}
            fields={initialValues ? convertObjectToArray(initialValues) : []}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Название"
              name="name"
              rules={[{ required: true, message: "Введите название" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="category"
              label="Категория"
              rules={[{ required: true, message: "Выберите категорию" }]}
            >
              <Select
                style={{ width: "100%" }}
                options={Object.entries(ECategories).map((item) => ({
                  value: item[0],
                  label: item[1],
                }))}
              />
            </Form.Item>

            {initialValues && !chooseNewFile && (
              <>
                <p>Файл: {initialValues?.pure_name}</p>
                <Button
                  onClick={() => setChooseNewFile(true)}
                  style={{ margin: "10px 0 30px 0" }}
                  size="small"
                >
                  Выбрать новый файл
                </Button>
              </>
            )}

            {(!initialValues || chooseNewFile) && (
              <Form.Item
                label={
                  <span>
                    <span style={{ color: "#ff4d4f" }}>*</span>
                    {"  "}Файл
                  </span>
                }
              >
                <Form.Item
                  name="file"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  noStyle
                  rules={[{ required: true, message: "Загрузите файл" }]}
                  style={{ backgroundColor: "red" }}
                >
                  <Upload.Dragger
                    name="files"
                    maxCount={1}
                    beforeUpload={beforeUpload}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p
                      className="ant-upload-text"
                      style={{ padding: "0 10px" }}
                    >
                      Кликните или перетащите файл сюда
                    </p>
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>
            )}
            {isError && (
              <Alert
                style={{ margin: "20px 0" }}
                message="Что-то пошло не так"
                type="error"
              />
            )}

            <Form.Item style={{ textAlign: "center" }}>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default FileForm;
