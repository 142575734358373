import { Link } from "react-router-dom";
import SearchInput from "../Search/Search";
import Title from "../Title/Title";
import s from "./Header.module.css";
import { Button } from "antd";
import { FC } from "react";

const Header: FC<{ logout: VoidFunction }> = ({ logout }) => {
  return (
    <div>
      <div className={s.headerWrapper}>
        <Link to="/" className={s.logoLink}>
          <img
            alt={"Ссылка на главную страницу"}
            src="/images/logo.svg"
            width={220}
            height={45}
          />
        </Link>
        <Title size="s">FTP Клиент</Title>
        <SearchInput />
        <Button onClick={logout} style={{ marginLeft: "auto" }}>
          Выход
        </Button>
      </div>
    </div>
  );
};

export default Header;
