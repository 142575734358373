import Title from "../Title/Title";
import { API } from "../../app/services/ApiService";
import TableOfFiles from "../TableOfFiles/TableOfFiles";
import { useAppDispatch } from "../../app/hooks";
import { useEffect } from "react";
import { chooseSortType } from "../../app/commonSlice";

const RecentAdditions = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(chooseSortType("newFirst"));
  }, []);

  const [getFiles, { data: files, isFetching: filesIsLoading }] =
    API.useLazyGetFilesQuery();

  return (
    <div style={{ position: "relative" }}>
      <Title>Последние добавления</Title>

      <TableOfFiles
        isLoading={filesIsLoading}
        files={files}
        updateFileList={() => getFiles({ orderDate: false })}
      />
    </div>
  );
};

export default RecentAdditions;
