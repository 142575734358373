import Title from "../Title/Title";
import { API } from "../../app/services/ApiService";
import TableOfFiles from "../TableOfFiles/TableOfFiles";
import { useAppSelector } from "../../app/hooks";
import { getSortType } from "../../utils/getSortType";

const Favorites = () => {
  const sortType = useAppSelector((state) => state.commonSlice.sortType);

  const [getFiles, { data: files, isFetching: filesIsLoading }] =
    API.useLazyGetFavoriteFilesQuery();

  return (
    <div style={{ position: "relative" }} className="contentWrapper">
      <Title>Избранное</Title>

      <TableOfFiles
        isLoading={filesIsLoading}
        files={files}
        updateFileList={() => getFiles(getSortType(sortType))}
      />
    </div>
  );
};

export default Favorites;
