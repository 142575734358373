import { Card } from "antd";
import { FC, ReactNode } from "react";
import s from "./ContentTypeCard.module.css";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  count: number;
  icon: ReactNode;
  iconColor: string;
  link: string;
  size: string;
}

const ContentTypeCard: FC<Props> = ({
  title,
  count,
  icon,
  iconColor,
  link,
  size,
}) => {
  return (
    <div>
      <Link key={link} className={s.link} to={`/content/${link}`}>
        <Card size="small" className={s.card}>
          <div className={s.cardContent}>
            <div
              className={s.iconWrapper}
              style={{ backgroundColor: iconColor }}
            >
              {icon}
            </div>
            <div>
              <p className={s.title}>{title}</p>
              <p className={s.addData}>{`${count} шт. / ${size}`}</p>
            </div>
          </div>
        </Card>
      </Link>
    </div>
  );
};

export default ContentTypeCard;
