import React from "react";
import { ClockCircleOutlined, StarOutlined } from "@ant-design/icons";
import { MenuProps } from "antd";
import { Menu as MenuComponent } from "antd";
import { Link } from "react-router-dom";
import s from "./Menu.module.css";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuProps["items"] = [
  getItem(
    <Link className={s.link} to="/recent-additions">
      Последние добавления
    </Link>,
    "sub1",
    <ClockCircleOutlined />
  ),

  getItem(
    <Link className={s.link} to="/favorites">
      Избранное
    </Link>,
    "sub2",
    <StarOutlined />
  ),
];

const Menu = () => {
  return (
    <>
      <MenuComponent
        style={{
          height: "100%",
          textAlign: "left",
        }}
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        items={items}
      />
    </>
  );
};

export default Menu;
