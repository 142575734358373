import React, { useEffect } from "react";
import { Alert, Button, Form, Input } from "antd";
import { API } from "../../app/services/ApiService";
import { ILoginValues } from "../../app/types";
import { useNavigate } from "react-router-dom";
import { setCookie } from "../../utils/cookies";

const Login: React.FC<{ setIsAuth: (payload: boolean) => void }> = ({
  setIsAuth,
}) => {
  const [login, { isSuccess, data, isError, reset }] = API.useLoginMutation();
  const onFinish = (values: ILoginValues) => {
    const formData = new FormData();
    formData.append("username", values.username);
    formData.append("password", values.password);
    login(formData);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess && data) {
      process.env.NODE_ENV === "development" &&
        setCookie("test_token", "token for development");
      navigate("/", { replace: true });
      reset();
    }
  }, [isSuccess]);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Form
        style={{
          minWidth: "400px",
        }}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label={<span style={{ textAlign: "left" }}>Имя пользователя</span>}
          name="username"
          rules={[{ required: true, message: "Введите имя" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<span style={{ textAlign: "left" }}>Пароль</span>}
          name="password"
          rules={[{ required: true, message: "Введите пароль" }]}
        >
          <Input.Password />
        </Form.Item>

        {isError && (
          <Alert
            message="Что-то пошло не так"
            type="error"
            style={{ margin: "10px 0" }}
          />
        )}
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Войти
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
