import Title from "../Title/Title";
import { API } from "../../app/services/ApiService";
import TableOfFiles from "../TableOfFiles/TableOfFiles";
import { useAppSelector } from "../../app/hooks";
import { useEffect } from "react";

const SearchPage = () => {
  const [search, { data: searchData, isFetching }] = API.useLazySearchQuery({});

  const searchQuery = useAppSelector((state) => state.commonSlice.searchQuery);
  const searchFn = () => {
    search({ fileName: searchQuery });
  };

  useEffect(() => {
    searchFn();
  }, [searchQuery]);

  return (
    <div style={{ position: "relative" }}>
      <div className="contentWrapper">
        <Title>Результаты</Title>
        <TableOfFiles
          showSort={false}
          files={searchData}
          updateFileList={searchFn}
          isLoading={isFetching}
        />
      </div>
    </div>
  );
};

export default SearchPage;
