import React, { FC } from "react";
import { StarOutlined, StarFilled } from "@ant-design/icons";
import s from "./FavoriteButton.module.css";
import { Tooltip } from "antd";

const FavoriteButton: FC<{
  isFavorite: boolean;
  setIsFavorite: () => void;
}> = ({ isFavorite, setIsFavorite }) =>
  isFavorite ? (
    <button
      className={s.favoriteButton}
      style={{
        color: "#ffd60a",
      }}
      onClick={setIsFavorite}
    >
      <StarFilled />
    </button>
  ) : (
    <Tooltip title="Избранное">
      <button
        className={s.favoriteButton}
        style={{
          color: "var(--coldGrey)",
          opacity: 0.4,
        }}
        onClick={setIsFavorite}
      >
        <StarOutlined />
      </button>
    </Tooltip>
  );

export default FavoriteButton;
