import { Popover } from "antd";
import { FC, useEffect, useState } from "react";
import { API } from "../../app/services/ApiService";
import Spinner from "../StatusComponents/Spinner/Spinner";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

interface Props {
  id: string | number;
}

const CopyLink: FC<Props> = ({ id }) => {
  const [getShortLink, { data: shortLinkData }] = API.useLazyShortLinkQuery();
  const [copyDataStatus, setCopyDataStatus] = useState<
    "success" | "error" | null
  >(null);

  useEffect(() => {
    if (shortLinkData?.data) {
      navigator.clipboard
        .writeText(shortLinkData?.data.url)
        .then(() => {
          setCopyDataStatus("success");
        })
        .catch(() => {
          setCopyDataStatus("error");
        });
    }
  }, [shortLinkData]);

  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const popoverContentStyle = {
    padding: "5px 15px",
  };
  const popoverIconStyle = {
    paddingRight: "5px",
  };

  return (
    <Popover
      content={
        copyDataStatus == "success" ? (
          <p style={popoverContentStyle}>
            <CheckCircleTwoTone
              style={popoverIconStyle}
              twoToneColor="#52c41a"
            />
            Скопировано
          </p>
        ) : copyDataStatus == "error" ? (
          <p style={popoverContentStyle}>
            <CloseCircleTwoTone
              style={popoverIconStyle}
              twoToneColor="#eb2f96"
            />
            Ошибка
          </p>
        ) : (
          <Spinner style={{ padding: "5px 15px" }} size="sm" />
        )
      }
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <a className="link" onClick={() => getShortLink({ id: id })}>
        Скопировать ссылку
      </a>
    </Popover>
  );
};

export default CopyLink;
