import s from "./Home.module.css";
import {
  PictureOutlined,
  FileOutlined,
  DotChartOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import FileForm from "../FileForm/FileForm";
import ContentTypeCard from "../ContentTypeCard/ContentTypeCard";
import Title from "../Title/Title";
import { Button } from "antd";
import RecentAdditions from "../RecentAdditions/RecentAdditions";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { API } from "../../app/services/ApiService";
import {
  ECategories,
  ECategoriesKeys,
  IFullCategoriesData,
} from "../../app/types";
import Spinner from "../StatusComponents/Spinner/Spinner";

const Home = () => {
  const [isFileCreationModalOpen, setIsFileCreationModalOpen] = useState(false);

  const [
    uploadFile,
    {
      isError: isUploadError,
      isSuccess: isUploadSuccess,
      isLoading: isUploadLoading,
    },
  ] = API.useUploadFileMutation();

  useEffect(() => {
    if (isUploadSuccess) {
      setIsFileCreationModalOpen(false);
    }
  }, [isUploadSuccess]);

  const categories = {
    image: {
      title: ECategories["image"],
      icon: <PictureOutlined />,
      iconColor: "var(--error)",
      link: "image",
    },
    document: {
      title: ECategories["document"],
      icon: <FileOutlined />,
      iconColor: "var(--darkGrey)",
      link: "document",
    },
    video: {
      title: ECategories["video"],
      icon: <VideoCameraOutlined />,
      iconColor: "#0077b6",
      link: "video",
    },
    other: {
      title: ECategories["other"],
      icon: <DotChartOutlined />,
      iconColor: "var(--yellow)",
      link: "other",
    },
  };

  const { data: commonFilesData, isLoading } = API.useCommonInfoQuery();

  if (!commonFilesData || isLoading) {
    return <Spinner />;
  }

  const fullCategories = Object.entries(categories)
    .map(([key, value]) => {
      let finalArray = {};
      commonFilesData.files.forEach((file) => {
        if (ECategories[file.category as ECategoriesKeys] === value.title) {
          finalArray = { ...finalArray, ...value, ...file };
        }
      });
      return finalArray;
    })
    .filter((obj) => Object.keys(obj).length > 0) as Array<IFullCategoriesData>;

  return (
    <div className={"contentWrapper"}>
      <div className={s.actionsWrapper}>
        <Button
          onClick={() => setIsFileCreationModalOpen(true)}
          type="primary"
          icon={<PlusOutlined />}
          size={"middle"}
        >
          Добавить файл
        </Button>

        <FileForm
          isModalOpen={isFileCreationModalOpen}
          setIsModalOpen={setIsFileCreationModalOpen}
          saveData={(data) => {
            uploadFile(data);
          }}
          isError={isUploadError}
          isLoading={isUploadLoading}
        />
      </div>

      <Title>Категории</Title>
      <p
        className={s.commonDate}
      >{`${commonFilesData.total.count} шт. / ${commonFilesData.total.total_size}`}</p>

      <div className={s.cardsWrapper}>
        {fullCategories.map((item) => {
          return <ContentTypeCard key={item.title} {...item} />;
        })}
      </div>
      <RecentAdditions />
    </div>
  );
};

export default Home;
