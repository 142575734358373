import { configureStore } from "@reduxjs/toolkit";
import { API } from "./services/ApiService";
import commonSlice from "./commonSlice";
import { rtkQueryErrorLogger } from "./services/ErrorsHandler";

export const store = configureStore({
  reducer: {
    commonSlice,
    [API.reducerPath]: API.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(API.middleware).concat(rtkQueryErrorLogger),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
