import { Route, Routes } from "react-router-dom";
import Home from "../../../components/Home/Home";
import MainLayout from "../../../components/Layouts/MainLayout";
import RecentAdditions from "../../../components/RecentAdditions/RecentAdditions";
import ErrorPage from "../../../components/StatusComponents/ErrorPage";
import Favorites from "../../../components/Favorites/Favorites";
import ContentTypePage from "../../../components/ContentTypePage/ContentTypePage";
import Login from "../../../components/Login/Login";
import ProtectedRoute from "../../../hoc/ProtectedRoute";
import SearchPage from "../../../components/SearchPage/SearchPage";
import { API } from "../../services/ApiService";
import { deleteCookie } from "../../../utils/cookies";

function App() {
  const [logout, {}] = API.useLogoutMutation();

  const logoutFn = () => {
    logout();
    process.env.NODE_ENV === "development" && deleteCookie("test_token");
  };

  return (
    <>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/login" element={<Login setIsAuth={() => {}} />} />
          <Route element={<MainLayout logout={logoutFn} />}>
            <Route path="/" element={<Home />} />
            <Route path="/search" element={<SearchPage />} />
            <Route
              path="/recent-additions"
              element={
                <div className="contentWrapper">
                  <RecentAdditions />
                </div>
              }
            />
            <Route path="/favorites" element={<Favorites />} />
            <Route path="/content/:type" element={<ContentTypePage />} />
          </Route>
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
