import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ESortKeys } from "./types";

const initialState = {
  sortType: "newFirst" as ESortKeys,
  searchQuery: "",
};

export const commonSlice = createSlice({
  name: "orderPayment",
  initialState,
  reducers: {
    chooseSortType: (state, action: PayloadAction<ESortKeys>) => {
      state.sortType = action.payload;
    },
    updateSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
  },
});

export const { chooseSortType, updateSearchQuery } = commonSlice.actions;

export default commonSlice.reducer;
